import React, { ReactNode } from 'react';
import cn from 'classnames';

import { WrapperNotification } from 'components/ui';
import { DARK_MODE_CLASS, useTheme } from 'contexts/ThemeContextProvider';

import './index.css';
import 'react-toastify/dist/ReactToastify.min.css';

type LayoutProps = {
  children: ReactNode;
};

export const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { theme } = useTheme();

  return (
    <>
      <div className={cn(theme === DARK_MODE_CLASS ? `${DARK_MODE_CLASS} bg-blue-500` : 'bg-white-300')}>
        {children}
        <WrapperNotification />
      </div>
    </>
  );
};
